<script>
  import LL from "$i18n/i18n-svelte";
</script>

<section>
  <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2">
    <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
      <h2 class="mb-4 text-4xl tracking-tight font-semibold text-gray-900 dark:text-white">{$LL.hero.title()}</h2>
      <p class="mb-4">
        {$LL.hero.paragraph1()}
      </p>
      <p>
        {$LL.hero.paragraph2()}
      </p>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-8">
      <img class="w-full rounded-lg max-h-80 object-cover" src="/images/cycling/subscribe3.webp" alt="Cycling 1" />
      <img class="mt-4 w-full lg:mt-10 rounded-lg" src="/images/cycling/subscribe2.webp" alt="Cycling 2" />
    </div>
  </div>
</section>
