<script>
  import LL from "$i18n/i18n-svelte";
</script>

<section>
  <div class="pt-8 px-4 mx-auto max-w-screen-xl text-center">
    <h1
      class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white"
    >
      {$LL.launch.title()}
    </h1>
    <p
      class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400"
    >
      {$LL.launch.paragraph1()}
    </p>
    <!-- <p
      class="mb-2 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400"
    >
      {$LL.launch.paragraph2()}
    </p> -->
    <p
      class="mb-6 text-lg font-bold text-emerald-500 lg:text-xl sm:px-16 xl:px-48 dark:text-emerald-400"
    >
    {$LL.launch.paragraph2()}
    </p>
  </div>
</section>
